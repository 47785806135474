import getCurrenciesData from "../mock_data/getCurrencies.data";

export default function handleOnboarding(setShowOverlay, setData, setError) {
  setShowOverlay(true);
  // if (!window.OrendaGetResponse || window.isSiteBuilder) {
  setTimeout(() => {
    setData(getCurrenciesData.currencies);
    setShowOverlay(false);
  }, 1000);
  return;
  // }

  // return window.OrendaGetResponse(
  //   {
  //     event_name: "getCurrencies",
  //   },
  //   (response) => {
  //     if (response.error) {
  //       setShowOverlay(false);
  //       return setError(true);
  //     }
  //     if (!response.currencies) return setShowOverlay(false);
  //     setData(response.currencies);
  //     return setShowOverlay(false);
  //   }
  // );
}
