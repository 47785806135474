import getFxRatesData from "../mock_data/getFxRates.data";
import currenciesData from "../mock_data/getCurrencies.data";

export default function handleOnboarding(
  setShowOverlay,
  setData,
  setError,
  currency
) {
  setShowOverlay(true);
  if (!window.OrendaGetResponse || window.isSiteBuilder) {
    setTimeout(() => {
      setData(
        getFxRatesData.fx_rates.filter((f) => f.dest_currency !== currency)
      );
      setShowOverlay(false);
    }, 1000);
    return;
  }

  return window.OrendaGetResponse(
    {
      event_name: "getFxRates",
      data: JSON.stringify({
        base_currency: currency,
        dest_currencies: currenciesData.currencies.filter(
          (f) => f.currency !== currency
        ),
      }),
    },
    (response) => {
      if (response.error) {
        setShowOverlay(false);
        return setError(true);
      }
      if (!response.fx_rates) return setShowOverlay(false);
      setData(response.fx_rates);
      return setShowOverlay(false);
    }
  );
}
