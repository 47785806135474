const data = {
  currencies: [
    {
      name: "US Dollar",
      currency: "USD",
    },
    {
      name: "Euro",
      currency: "EUR",
    },
    {
      name: "British Pound",
      currency: "GBP",
    },
    {
      name: "Canadian Dollar",
      currency: "CAD",
    },
    {
      name: "Australian Dollar",
      currency: "AUD",
    },
    {
      name: "Japanese Yen",
      currency: "JPY",
    },
    {
      name: "Indian Rupee",
      currency: "INR",
    },
    {
      name: "New Zealand Dollar",
      currency: "NZD",
    },
    {
      name: "Swiss Franc",
      currency: "CHF",
    },
    {
      name: "South African Rand",
      currency: "ZAR",
    },
  ],
};

export default data;
