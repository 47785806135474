const data = {
  fx_rates: [
    {
      base_currency: "GBP",
      dest_name: "US Dollar",
      dest_currency: "USD",
      fx_rate: "1.286740000",
    },
    {
      base_currency: "GBP",
      dest_name: "Euro",
      dest_currency: "EUR",
      fx_rate: "1.157576000",
    },
    {
      base_currency: "GBP",
      dest_name: "British Pound",
      dest_currency: "GBP",
      fx_rate: "1.000000000",
    },
    {
      base_currency: "GBP",
      dest_name: "Canadian Dollar",
      dest_currency: "CAD",
      fx_rate: "1.592061000",
    },
    {
      base_currency: "GBP",
      dest_name: "Australian Dollar",
      dest_currency: "AUD",
      fx_rate: "1.699634000",
    },
    {
      base_currency: "GBP",
      dest_name: "Japanese Yen",
      dest_currency: "JPY",
      fx_rate: "155.646200000",
    },
    {
      base_currency: "GBP",
      dest_name: "Indian Rupee",
      dest_currency: "INR",
      fx_rate: "96.262800000",
    },
    {
      base_currency: "GBP",
      dest_name: "New Zealand Dollar",
      dest_currency: "NZD",
      fx_rate: "1.844552000",
    },
    {
      base_currency: "GBP",
      dest_name: "Swiss Franc",
      dest_currency: "CHF",
      fx_rate: "1.185728000",
    },
    {
      base_currency: "GBP",
      dest_name: "South African Rand",
      dest_currency: "ZAR",
      fx_rate: "18.527970000",
    },
  ],
};

export default data;
