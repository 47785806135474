import React, { useState, useEffect } from "react";
import { Row, Col, Table, Form } from "react-bootstrap";
import ClipLoader from "react-spinners/BounceLoader";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";

import getFxRates from "./lib/getFxRates";
import getCurrencies from "./lib/getCurrencies";

import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [currency, setCurrency] = useState(null);
  const [fxRates, setFxRates] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [showOverlay, setShowOverlay] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getCurrencies(setShowOverlay, setCurrencies, setError);
  }, []);

  useEffect(() => {
    if (currency) getFxRates(setShowOverlay, setFxRates, setError, currency);
  }, [currency]);

  useEffect(() => {
    if (error) {
      toast.error("Unfortunately an error occurred.");
      setError(false);
    }
  }, [error]);

  return (
    <div className="orenda-frontend">
      {showOverlay && (
        <div className="d-flex flex-column justify-content-center align-items-center v-100 v-100">
          <p>Please wait...</p>
          <ClipLoader size={30} />
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={showOverlay ? "d-none" : null}>
        <Row className="m-3">
          <Col lg={12}>
            <h3 className="text-center">FX Rates</h3>

            <Form.Group controlId="setCurrency" className="mb-3">
              <Form.Label>Choose base currency</Form.Label>
              <Select
                options={currencies.map((c) => ({
                  value: c.currency,
                  label: `${c.name} (${c.currency})`,
                }))}
                isClearable={true}
                onChange={(x) => setCurrency(x ? x.value : null)}
                className="noborder"
                classNamePrefix="or-select"
              />
            </Form.Group>

            {(fxRates?.length && currency && (
              <Row className="mt-3 justify-content-center">
                <Col lg={6} md={8} sm={12}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <strong>Currency</strong>
                    </div>
                    <div>
                      <strong>Rate</strong>
                    </div>
                  </div>
                  {fxRates?.map((m, i) => (
                    <div key={i} className="d-flex justify-content-between">
                      <div>{`${m.dest_name} (${m.dest_currency})`}</div>
                      <div>{m.fx_rate || 0}</div>
                    </div>
                  ))}
                </Col>
              </Row>
            )) ||
              null}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default App;
